import { t } from "i18next"
import { IMultiSelectPopoverContent } from "../components/molecules/MultiSelectPopover"

export const handlingChargeOptions = [
  {
    label: "紹介手数料型",
    value: "理論年収の",
  },
  {
    label: "固定報酬型",
    value: "固定報酬",
  },
]

export const jobPeriodOptions = [
  {
    target: "job_overview.no_of_hire_per_period.unit",
    label: t("Year"),
    value: "Year",
  },
  {
    target: "job_overview.no_of_hire_per_period.unit",
    label: t("Month"),
    value: "Month",
  },
]

export const jobIndustryType = [
  {
    target: "industry_type",
    label: "IT",
    value: "IT",
  },
  {
    target: "industry_type",
    label: "メーカー",
    value: "メーカー",
  },
  {
    target: "industry_type",
    label: "商社",
    value: "商社",
  },
  {
    target: "industry_type",
    label: "人材・教育",
    value: "人材・教育",
  },
  {
    target: "industry_type",
    label: "コンサル",
    value: "コンサル",
  },
  {
    target: "industry_type",
    label: "広告・出版・マスコミ",
    value: "広告・出版・マスコミ",
  },
  {
    target: "industry_type",
    label: "金融・保険",
    value: "金融・保険",
  },
  {
    target: "industry_type",
    label: "不動産・建築",
    value: "不動産・建築",
  },
  {
    target: "industry_type",
    label: "サービス・流通",
    value: "サービス・流通",
  },
]

export const genderType = [
  {
    target: "gender_type",
    label: t("Male"),
    value: "男CH",
  },
  {
    target: "gender_type",
    label: "男性が好ましい",
    value: "男性が好ましいCH",
  },
  {
    target: "gender_type",
    label: "不問",
    value: "不問CH",
  },
  {
    target: "gender_type",
    label: t("Female"),
    value: "女CH",
  },
  {
    target: "gender_type",
    label: "女性が好ましい",
    value: "女性が好ましいCH",
  },
]

export const jobOccupationType = [
  {
    target: "occupation_type",
    label: "新規開拓営業",
    value: "新規開拓営業",
  },
  {
    target: "occupation_type",
    label: "代理店・パートナ営業",
    value: "代理店・パートナ営業",
  },
  {
    target: "occupation_type",
    label: "営業企画",
    value: "営業企画",
  },
  {
    target: "occupation_type",
    label: "インサイドセールス",
    value: "インサイドセールス",
  },
  {
    target: "occupation_type",
    label: "カスタマーサクセス・サポート",
    value: "カスタマーサクセス・サポート",
  },
  {
    target: "occupation_type",
    label: "管理職",
    value: "管理職",
  },
  {
    target: "occupation_type",
    label: "フィールドセールス",
    value: "フィールドセールス",
  },
  {
    target: "occupation_type",
    label: "その他営業（人事、営業サポート、事務）",
    value: "その他営業（人事、営業サポート、事務）",
  },
]

export const jobEducationHistory = [
  {
    target: "education_history",
    label: "学歴不問",
    value: "学歴不問",
  },
  {
    target: "education_history",
    label: "高校卒",
    value: "高校卒",
  },
  {
    target: "education_history",
    label: "専門卒",
    value: "専門卒",
  },
  {
    target: "education_history",
    label: "大卒",
    value: "大卒",
  },
  {
    target: "education_history",
    label: "大卒以上",
    value: "大卒以上",
  },
]

export const jobHandlingCharge = [
  {
    target: "handling_charge",
    label: "20%以上",
    value: "20%以上",
  },
  {
    target: "handling_charge",
    label: "25%以上",
    value: "25%以上",
  },
  {
    target: "handling_charge",
    label: "30%以上",
    value: "30%以上",
  },
  {
    target: "handling_charge",
    label: "35%以上",
    value: "35%以上",
  },
  {
    target: "handling_charge",
    label: "40万円以上",
    value: "40万円以上",
  },
  {
    target: "handling_charge",
    label: "50万円以上",
    value: "50万円以上",
  },
  {
    target: "handling_charge",
    label: "60万円以上",
    value: "60万円以上",
  },
  {
    target: "handling_charge",
    label: "70万円以上",
    value: "70万円以上",
  },
  {
    target: "handling_charge",
    label: "80万円以上",
    value: "80万円以上",
  },
  {
    target: "handling_charge",
    label: "90万円以上",
    value: "90万円以上",
  },
  {
    target: "handling_charge",
    label: "100万円以上",
    value: "100万円以上",
  },
]

export const importantDocumentItems = [
  {
    target: "job_offer_point.message_from_hr.important_items",
    label: "学歴",
    value: "学歴",
  },
  {
    target: "job_offer_point.message_from_hr.important_items",
    label: "年齢",
    value: "年齢",
  },
  {
    target: "job_offer_point.message_from_hr.important_items",
    label: "転職回数",
    value: "転職回数",
  },
  {
    target: "job_offer_point.message_from_hr.important_items",
    label: "スキル",
    value: "スキル",
  },
  {
    target: "job_offer_point.message_from_hr.important_items",
    label: "経験",
    value: "経験",
  },
  {
    target: "job_offer_point.message_from_hr.important_items",
    label: "企業文化",
    value: "企業文化",
  },
  {
    target: "job_offer_point.message_from_hr.important_items",
    label: "年収",
    value: "年収",
  },
]

export const jobSelectionFlow = [
  {
    target: "job_offer_point.message_from_hr.selection_flow",
    label: "適性検査",
    value: "適性検査",
  },
  {
    target: "job_offer_point.message_from_hr.selection_flow",
    label: "書類",
    value: "書類",
  },
  {
    target: "job_offer_point.message_from_hr.selection_flow",
    label: "1次面接",
    value: "1次面接",
  },
  {
    target: "job_offer_point.message_from_hr.selection_flow",
    label: "2次面接",
    value: "2次面接",
  },
  {
    target: "job_offer_point.message_from_hr.selection_flow",
    label: "3次面接",
    value: "3次面接",
  },
  {
    target: "job_offer_point.message_from_hr.selection_flow",
    label: "最終面接",
    value: "最終面接",
  },
]

export const jobArea = [
  {
    target: "area",
    label: "北海道",
    value: "北海道",
  },
  {
    target: "area",
    label: "青森",
    value: "青森",
  },
  {
    target: "area",
    label: "岩手",
    value: "岩手",
  },
  {
    target: "area",
    label: "宮城",
    value: "宮城",
  },
  {
    target: "area",
    label: "秋田",
    value: "秋田",
  },
  {
    target: "area",
    label: "山形",
    value: "山形",
  },
  {
    target: "area",
    label: "福島",
    value: "福島",
  },
  {
    target: "area",
    label: "茨城",
    value: "茨城",
  },
  {
    target: "area",
    label: "栃木",
    value: "栃木",
  },
  {
    target: "area",
    label: "群馬",
    value: "群馬",
  },
  {
    target: "area",
    label: "埼玉",
    value: "埼玉",
  },
  {
    target: "area",
    label: "千葉",
    value: "千葉",
  },
  {
    target: "area",
    label: "東京",
    value: "東京",
  },
  {
    target: "area",
    label: "神奈川",
    value: "神奈川",
  },
  {
    target: "area",
    label: "新潟",
    value: "新潟",
  },
  {
    target: "area",
    label: "富山",
    value: "富山",
  },
  {
    target: "area",
    label: "石川",
    value: "石川",
  },
  {
    target: "area",
    label: "福井",
    value: "福井",
  },
  {
    target: "area",
    label: "山梨",
    value: "山梨",
  },
  {
    target: "area",
    label: "長野",
    value: "長野",
  },
  {
    target: "area",
    label: "岐阜",
    value: "岐阜",
  },
  {
    target: "area",
    label: "静岡",
    value: "静岡",
  },
  {
    target: "area",
    label: "愛知",
    value: "愛知",
  },
  {
    target: "area",
    label: "三重",
    value: "三重",
  },
  {
    target: "area",
    label: "滋賀",
    value: "滋賀",
  },
  {
    target: "area",
    label: "京都",
    value: "京都",
  },
  {
    target: "area",
    label: "大阪",
    value: "大阪",
  },
  {
    target: "area",
    label: "兵庫",
    value: "兵庫",
  },
  {
    target: "area",
    label: "奈良",
    value: "奈良",
  },
  {
    target: "area",
    label: "和歌山",
    value: "和歌山",
  },
  {
    target: "area",
    label: "鳥取",
    value: "鳥取",
  },
  {
    target: "area",
    label: "島根",
    value: "島根",
  },
  {
    target: "area",
    label: "岡山",
    value: "岡山",
  },
  {
    target: "area",
    label: "広島",
    value: "広島",
  },
  {
    target: "area",
    label: "山口",
    value: "山口",
  },
  {
    target: "area",
    label: "徳島",
    value: "徳島",
  },
  {
    target: "area",
    label: "香川",
    value: "香川",
  },
  {
    target: "area",
    label: "愛媛",
    value: "愛媛",
  },
  {
    target: "area",
    label: "高知",
    value: "高知",
  },
  {
    target: "area",
    label: "福岡",
    value: "福岡",
  },
  {
    target: "area",
    label: "佐賀",
    value: "佐賀",
  },
  {
    target: "area",
    label: "長崎",
    value: "長崎",
  },
  {
    target: "area",
    label: "熊本",
    value: "熊本",
  },
  {
    target: "area",
    label: "大分",
    value: "大分",
  },
  {
    target: "area",
    label: "宮崎",
    value: "宮崎",
  },
  {
    target: "area",
    label: "鹿児島",
    value: "鹿児島",
  },
  {
    target: "area",
    label: "沖縄",
    value: "沖縄",
  },
]

export const experiencedYearList = [
  {
    target: "yearlist",
    label: "1年",
    value: "1年",
  },
  {
    target: "yearlist",
    label: "2年",
    value: "2年",
  },
  {
    target: "yearlist",
    label: "3年",
    value: "3年",
  },
  {
    target: "yearlist",
    label: "4年",
    value: "4年",
  },
  {
    target: "yearlist",
    label: "5年",
    value: "5年",
  },
  {
    target: "yearlist",
    label: "6年",
    value: "6年",
  },
  {
    target: "yearlist",
    label: "7年",
    value: "7年",
  },
  {
    target: "yearlist",
    label: "8年",
    value: "8年",
  },
  {
    target: "yearlist",
    label: "9年",
    value: "9年",
  },
  {
    target: "yearlist",
    label: "10年",
    value: "10年",
  },
]

export const jobStatusListGraph = [
  "書類確認",
  "1次面接",
  "2次面接",
  "3次面接",
  "最終面接",
]

export const jobTypeList = [
  {
    label: t("Sales agent support"),
    value: "sales-agent-support",
  },
  { label: t("Internal job"), value: "shared-job" },
  { label: t("Direct job"), value: "direct-job" },
]

export const newJobTypeList = [
  {
    label: t("All"),
    value: "all",
  },
  {
    label: t("Sales agent support"),
    value: "sales-agent-support",
  },
  { label: t("Internal job"), value: "shared-job" },

  //FIXME : change value for internal job once confirmed in backend
  // { label: t("Internal job"), value: "shared-job" },
  { label: t("Direct job"), value: "direct-job" },
]
export const dataOccupation: Array<IMultiSelectPopoverContent> = [
  {
    title: t("Sales"),
    countLabel: "sales",
    checkOptions: [
      "Corporate Sales",
      "New sales",
      "Field sales",
      "Individual Sales",
      "Route sales, agency sales, liaison and outside sales",
      "Inside Sales",
      "Counter Sales",
      "Customer Success",
      "IT, technology and systems sales",
      "Medical sales (MR, medical device sales)",
      "International Sales",
      "Sales Manager/Sales Management",
      "Telemarketing, Operator, Call Center Management (SV)",
      "Human Resources Coordinator, Temporary Staffing Coordinator",
      "Career Consultant (CA)",
      "Recruiting Advisor (RA)",
      "Human resources sales",
      "Other Sales",
      "System Consultant, Pre-Sales",
    ],
  },
  {
    title: t("Planning, office work, marketing, and administration"),
    countLabel: "planning",
    checkOptions: [
      "Executive Management, Corporate Planning, Business Planning, Business Development",
      "New Business",
      "Advertising, PR, Public Relations, IR",
      "Sales Planning, Sales Promotion Planning, Product Planning, Product Development",
      "Web Marketing, Research, Data Analysis, SEO/SEM Consultant",
      "Human Resources",
      "Sales administration, secretarial, receptionist, customer support",
      "Interpretation, Translation",
      "Trade, logistics, purchasing, materials",
      "Logistics planning, logistics management, logistics, SCM",
      "Merchandise management, inventory control",
      "Purchasing and material procurement",
      "Other planning and management",
      "Project Managers",
      "Web Producer, Web Director, Web Planner",
    ],
  },
  {
    title: t("Sales and service (fashion, food, retail)"),
    countLabel: "sales_and_services",
    checkOptions: [
      "Merchandiser, Buyer, Distributor",
      "Supervisors",
      "Store Development, Franchise Development",
      "Store manager, store manager candidates",
      "Sales and customer service staff",
      "Other sales and service staff",
    ],
  },
  {
    title: t("Development"),
    countLabel: "development",
    checkOptions: [
      "SE/Engineer",
      "Frontend Engineer",
      "Backend Engineer",
      "Infrastructure Engineer",
      "Full-stack Engineer",
      "Mobile App Engineer",
      "Game Engineer",
      "Embedded Engineer",
      "Machine Learning Engineer",
      "AI Engineer",
      "IoT Engineer",
      "Quality Assurance Engineer",
      "IT Consultant",
      "Project Manager",
      "Project Management Office",
    ],
  },
  // {
  //   title: t("Sales Agent recommendation"),
  //   countLabel: "Sales Agent recommendation",
  //   checkOptions: ["Sales Agent recommendation"],
  // },
  {
    title: t("Others"),
    countLabel: "others",
    checkOptions: [
      "Office work, etc.",
      "Construction Management",
      "Planning/Administration",
      "Medical Professionals",
      "Financial Professionals",
      "Consultant",
      "Real Estate Professionals",
      "Creative",
    ],
  },
]

export const jobSeekersOccupationData = dataOccupation.map((item) => {
  if (item.countLabel === "others")
    return {
      title: t("Others"),
      countLabel: "others",
      checkOptions: [
        "Office work, etc.",
        "Construction Management",
        "Planning/Administration",
        "Medical Professionals",
        "Financial Professionals",
        "Consultant",
        "Real Estate Professionals",
        "Creative",
      ],
    }
  return item
})

export const dataIndustry: Array<IMultiSelectPopoverContent> = [
  {
    title: t("IT, Telecommunications, Internet"),
    countLabel: "telecommunication",
    checkOptions: [
      "Software and Information Processing",
      "Internet related",
      "Telecommunications",
    ],
  },
  {
    title: t("Maker"),
    countLabel: "maker",
    checkOptions: [
      "Computers, Telecommunications Equipment, Office Automation Equipment",
      "Semiconductors, electronics and electrical components",
      "Machinery",
      "Automobiles and transportation equipment",
      "Medical equipment",
      "Other manufacturers",
      "Housing, building materials and exteriors",
      "Fashion, Apparel & Accessories",
      "Foodstuffs",
    ],
  },
  {
    title: t("Trading company"),
    countLabel: "trading",
    checkOptions: ["General trading company", "Specialized trading company"],
  },
  {
    title: t("Service"),
    countLabel: "service",
    checkOptions: [
      "Food Service / Food & Beverage",
      "Temporary Staffing / Placement",
      "Teaching",
      "Medical, welfare and nursing care services",
      "Security, building management, maintenance and cleaning",
      "Esthetic, beauty, and barbering",
      "Leisure, amusement and sports facilities",
      "Hotel/Travel",
      "Service (Other)",
    ],
  },
  {
    title: t("Mass Communication, Advertising and Design"),
    countLabel: "mass_communication",
    checkOptions: [
      "Broadcasting, Newspaper, Publication",
      "Advertising, PR, SP, Events",
      "Design/Printing",
    ],
  },
  {
    title: t("Finance and Insurance"),
    countLabel: "finance",
    checkOptions: [
      "Banks",
      "Securities & Investment Banking",
      "Venture Capital",
      "Life Insurance",
      "Non-life insurance",
      "Credit & consumer credit",
      "Other finance",
    ],
  },
  {
    title: t("Consulting"),
    countLabel: "consulting",
    checkOptions: [
      "Think Tanks, Marketing & Research",
      "Specialized Consultants",
      "General Consultant",
    ],
  },
  {
    title: t("Real Estate, Construction & Equipment"),
    countLabel: "real_estate",
    checkOptions: [
      "Construction & Civil Engineering",
      "Design",
      "Real Estate",
      "Other Real Estate/Construction/Facilities",
    ],
  },
  {
    title: t("Transportation, traffic, logistics, warehousing"),
    countLabel: "logistics",
    checkOptions: [
      "Marine, Rail, Air & Land Transportation",
      "Logistics & Warehousing",
    ],
  },
  {
    title: t("Others"),
    countLabel: "others",
    checkOptions: [
      "Electricity, Gas, Water, Environment, Energy",
      "Associations, federations, public offices, independent administrative agencies",
      "Agriculture, forestry, fisheries, and mining",
      "Other industries",
    ],
  },
]
//hello world

export const RecruitmentFeaturesOptions = [
  { label: "職種未経験歓迎", value: "職種未経験歓迎" },
  { label: "業種未経験歓迎", value: "業種未経験歓迎" },
  { label: "第二新卒歓迎", value: "第二新卒歓迎" },
  { label: "学歴不問", value: "学歴不問" },
  { label: "ブランクOK", value: "ブランクOK" },
  { label: "ミドルが活躍", value: "ミドルが活躍" },
  { label: "急募！内定まで2週間", value: "急募！内定まで2週間" },
  { label: "募集人数10名以上", value: "募集人数10名以上" },
  { label: "面接1回のみ", value: "面接1回のみ" },
  { label: "Web面接OK", value: "Web面接OK" },
]

export const WorkplaceFeaturesOptions = [
  { label: "完全週休2日制", value: "完全週休2日制" },
  { label: "土日祝休み", value: "土日祝休み" },
  { label: "年間休日120日以上", value: "年間休日120日以上" },
  { label: "社宅・家賃補助制度", value: "社宅・家賃補助制度" },
  { label: "社員食堂・食事補助あり", value: "社員食堂・食事補助あり" },
  { label: "交通費全額支給", value: "交通費全額支給" },
  { label: "産休・育休取得実績あり", value: "産休・育休取得実績あり" },
  { label: "託児所・育児サポートあり", value: "託児所・育児サポートあり" },
  { label: "ストックオプションあり", value: "ストックオプションあり" },
  { label: "退職金あり", value: "退職金あり" },
  { label: "オフィス内禁煙・分煙", value: "オフィス内禁煙・分煙" },
  { label: "服装自由", value: "服装自由" },
  { label: "出産・育児支援制度あり", value: "出産・育児支援制度あり" },
]
export const WorkFeaturesOptions = [
  { label: "転勤なし", value: "転勤なし" },
  { label: "フレックス勤務", value: "フレックス勤務" },
  {
    label: "残業殆どなし（月20時間以内）",
    value: "残業殆どなし（月20時間以内）",
  },
  { label: "18時までに退社可能", value: "18時までに退社可能" },
  { label: "海外勤務・出張あり", value: "海外勤務・出張あり" },
  { label: "英語を使う仕事", value: "英語を使う仕事" },
  { label: "中国語を使う仕事", value: "中国語を使う仕事" },
  { label: "その他の言語を使う仕事", value: "その他の言語を使う仕事" },
  { label: "時短勤務OK", value: "時短勤務OK" },
  { label: "在宅勤務・リモートワークOK", value: "在宅勤務・リモートワークOK" },
  { label: "フルリモート", value: "フルリモート" },
]
export const CompanyFeaturesOptions = [
  { label: "外資系企業", value: "外資系企業" },
  { label: "中途入社50％以上", value: "中途入社50％以上" },
  { label: "女性社員50％以上", value: "女性社員50％以上" },
  { label: "平均年齢20代", value: "平均年齢20代" },
  { label: "20代の管理職登用実績あり", value: "20代の管理職登用実績あり" },
  { label: "女性管理職登用実績あり", value: "女性管理職登用実績あり" },
  { label: "子育てママ在籍中", value: "子育てママ在籍中" },
  { label: "独立支援制度あり", value: "独立支援制度あり" },
  { label: "副業OK", value: "副業OK" },
  { label: "上場企業", value: "上場企業" },
  { label: "IPO準備中", value: "IPO準備中" },
]
export const CharacteristicsOptions = [
  { label: "冷静", value: "冷静" },
  { label: "誠実", value: "誠実" },
  { label: "素直", value: "素直" },
  { label: "ユニーク", value: "ユニーク" },
  { label: "ストイック", value: "ストイック" },
  { label: "好奇心旺盛", value: "好奇心旺盛" },
  { label: "行動先行型", value: "行動先行型" },
  { label: "コミットメント力", value: "コミットメント力" },
  { label: "数値分析が得意", value: "数値分析が得意" },
  { label: "成長意欲が高い", value: "成長意欲が高い" },
  { label: "忍耐力がある", value: "忍耐力がある" },
  {
    label: "新しいことをしたい（新規事業をしたい）",
    value: "新しいことをしたい（新規事業をしたい）",
  },
  { label: "お金をしっかり稼ぎたい", value: "お金をしっかり稼ぎたい" },
  {
    label: "早期にマネジメント職につきたい",
    value: "早期にマネジメント職につきたい",
  },
  { label: "協調性がある", value: "協調性がある" },
  { label: "献身的である", value: "献身的である" },
]
export const ThoughtfulnessOptions = [
  { label: "ロジカル", value: "ロジカル" },
  { label: "計画性", value: "計画性" },
  { label: "経験重視", value: "経験重視" },
  { label: "大胆", value: "大胆" },
  { label: "慎重", value: "慎重" },
  { label: "楽観的", value: "楽観的" },
  { label: "主体性", value: "主体性" },
  { label: "柔軟性", value: "柔軟性" },
  { label: "ゼネラリスト", value: "ゼネラリスト" },
  { label: "スペシャリスト", value: "スペシャリスト" },
  { label: "顧客志向", value: "顧客志向" },
  { label: "トピックス", value: "トピックス" },
  { label: "マーケティングに興味あり", value: "マーケティングに興味あり" },
  { label: "正当に評価されたい", value: "正当に評価されたい" },
]
export const CompanyTypeOptions = [
  { label: "成長・ベンチャー企業型 ", value: "成長・ベンチャー企業型 " },
  { label: "安定・優良企業型", value: "安定・優良企業型" },
]
export const WorkingTypeOptions = [
  { label: "実力主義・権限委譲 ", value: "実力主義・権限委譲 " },
  { label: "コツコツ着実に業務を遂行", value: "コツコツ着実に業務を遂行" },
]

export const TopicsOptions = [
  { label: "職種未経験歓迎", value: "職種未経験歓迎" },
  { label: "業種未経験歓迎", value: "業種未経験歓迎" },
  { label: "第二新卒歓迎", value: "第二新卒歓迎" },
  { label: "学歴不問", value: "学歴不問" },
  { label: "ブランクOK", value: "ブランクOK" },
  { label: "ミドルが活躍", value: "ミドルが活躍" },
  { label: "急募！内定まで2週間", value: "急募！内定まで2週間" },
  { label: "募集人数10名以上", value: "募集人数10名以上" },
  { label: "面接1回のみ", value: "面接1回のみ" },
  { label: "Web面接OK", value: "Web面接OK" },
  { label: "完全週休2日制", value: "完全週休2日制" },
  { label: "土日祝休み", value: "土日祝休み" },
  { label: "年間休日120日以上", value: "年間休日120日以上" },
  { label: "社宅・家賃補助制度", value: "社宅・家賃補助制度" },
  { label: "社員食堂・食事補助あり", value: "社員食堂・食事補助あり" },
  { label: "交通費全額支給", value: "交通費全額支給" },
  { label: "産休・育休取得実績あり", value: "産休・育休取得実績あり" },
  { label: "託児所・育児サポートあり", value: "託児所・育児サポートあり" },
  { label: "ストックオプションあり", value: "ストックオプションあり" },
  { label: "退職金あり", value: "退職金あり" },
  { label: "オフィス内禁煙・分煙", value: "オフィス内禁煙・分煙" },
  { label: "服装自由", value: "服装自由" },
  { label: "出産・育児支援制度あり", value: "出産・育児支援制度あり" },
  { label: "転勤なし", value: "転勤なし" },
  { label: "フレックス勤務", value: "フレックス勤務" },
  {
    label: "残業殆どなし（月20時間以内）",
    value: "残業殆どなし（月20時間以内）",
  },
  { label: "18時までに退社可能", value: "18時までに退社可能" },
  { label: "海外勤務・出張あり", value: "海外勤務・出張あり" },
  { label: "英語を使う仕事", value: "英語を使う仕事" },
  { label: "中国語を使う仕事", value: "中国語を使う仕事" },
  { label: "その他の言語を使う仕事", value: "その他の言語を使う仕事" },
  { label: "時短勤務OK", value: "時短勤務OK" },
  { label: "在宅勤務・リモートワークOK", value: "在宅勤務・リモートワークOK" },
  { label: "外資系企業", value: "外資系企業" },
  { label: "中途入社50％以上", value: "中途入社50％以上" },
  { label: "女性社員50％以上", value: "女性社員50％以上" },
  { label: "平均年齢20代", value: "平均年齢20代" },
  { label: "20代の管理職登用実績あり", value: "20代の管理職登用実績あり" },
  { label: "女性管理職登用実績あり", value: "女性管理職登用実績あり" },
  { label: "子育てママ在籍中", value: "子育てママ在籍中" },
  { label: "独立支援制度あり", value: "独立支援制度あり" },
  { label: "副業OK", value: "副業OK" },
  { label: "上場企業", value: "上場企業" },
  { label: "naive", value: "naive" },
  { label: "誠実", value: "誠実" },
  { label: "素直", value: "素直" },
  { label: "ユニーク", value: "ユニーク" },
  { label: "ストイック", value: "ストイック" },
  { label: "好奇心旺盛", value: "好奇心旺盛" },
  { label: "行動先行型", value: "行動先行型" },
  { label: "コミットメント力", value: "コミットメント力" },
  { label: "ロジカル", value: "ロジカル" },
  { label: "計画性", value: "計画性" },
  { label: "経験重視", value: "経験重視" },
  { label: "大胆", value: "大胆" },
  { label: "慎重", value: "慎重" },
  { label: "楽観的", value: "楽観的" },
  { label: "主体性", value: "主体性" },
  { label: "柔軟性", value: "柔軟性" },
  { label: "ゼネラリスト", value: "ゼネラリスト" },
  { label: "スペシャリスト", value: "スペシャリスト" },
  { label: "顧客志向", value: "顧客志向" },
  { label: "成果報酬型", value: "成果報酬型" },
  { label: "成長・ベンチャー企業型 ", value: "成長・ベンチャー企業型 " },
  { label: "安定・優良企業型", value: "安定・優良企業型" },
  { label: "実力主義・権限委譲 ", value: "実力主義・権限委譲 " },
  { label: "コツコツ着実に業務を遂行", value: "コツコツ着実に業務を遂行" },
  { label: "フルリモート", value: "フルリモート" },
  { label: "数値分析が得意", value: "数値分析が得意" },
  { label: "成長意欲が高い", value: "成長意欲が高い" },
  { label: "忍耐力がある", value: "忍耐力がある" },
  {
    label: "新しいことをしたい（新規事業をしたい）",
    value: "新しいことをしたい（新規事業をしたい）",
  },
  { label: "お金をしっかり稼ぎたい", value: "お金をしっかり稼ぎたい" },
  {
    label: "早期にマネジメント職につきたい",
    value: "早期にマネジメント職につきたい",
  },
  { label: "IPO準備中", value: "IPO準備中" },
  { label: "冷静", value: "冷静" },
  { label: "成果報酬型", value: "成果報酬型" },
  { label: "協調性がある", value: "協調性がある" },
  { label: "献身的である", value: "献身的である" },
  { label: "マーケティングに興味あり", value: "マーケティングに興味あり" },
  { label: "正当に評価されたい", value: "正当に評価されたい" },
]

export const GetTopicsLabel = (topics) => {
  const options = []
  topics.map((valueItem) => {
    TopicsOptions.map((optionItem) => {
      if (valueItem === optionItem.value) {
        options.push(optionItem.label)
      }
    })
  })

  return options
}

export const JobCatagoryOptions = [
  {
    label: t("All"),
    value: "all",
  },
  { label: t("Entry requirements"), value: "Entry requirements" },
  { label: t("Work/Business content"), value: "Work/Business content" },
  { label: t("Topics"), value: "Topics" },
  { label: t("Employment conditions"), value: "Employment conditions" },
  {
    label: t("Information from recruiting Companies"),
    value: "Information from recruiting Companies",
  },
  {
    label: t("Sales agent limited information"),
    value: "Sales agent limited information",
  },
]
