import { API } from "../src/api"

export const fileUpload = async (formData) => {
  return API.post("/utils/upload-file", formData)
}

export type FileUploadParams = {
  formData: FormData
  fileCategory: string
}

// 求職者登録の際にファイルアップロードする関数
export const jobSeekerFileUpload = async (variables: FileUploadParams) => {
  const { formData, fileCategory } = variables
  return API.post("/utils/upload-file", formData, {
    // NOTE: ファイルアップロードの種類に応じて、渡すクエリを変更する
    // 履歴書の場合：?resume=true
    // 職務経歴書の場合：?portfolio=true
    params: { [fileCategory]: "true" },
  })
}

// moveUploadedFile の引数に string 型を明示
export const moveUploadedFile = async (file_url: string) => {
  return API.post("/utils/move-uploaded-file", {
    file_url: file_url.replace("default", "temp"),
  })
}
