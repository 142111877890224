import { firebase, db } from "../src/firebase"
import _ from "lodash"

const chatPageSize = 15

export enum JobRoomMessageState {
  AgentNotSeen = "AGENT_NOT_SEEN",
  CompanyNotSeen = "COMPANY_NOT_SEEN",
  AgentCompanyNotSeen = "AGENT_COMPANY_NOT_SEEN",
  DefaultState = "DEFAULT_STATE",
}
export interface IJobRoom {
  id: string
  job_id: string
  applicant_id: string
  agent_member_id: string
  agent_member_id_as_company?: string
  company_id?: string
  messages: IJobRoomMessage[]
  message_state: JobRoomMessageState | null
  updated_at: Date
  is_user_blocked?: boolean
  blocked_by?: string
  paginateEnable?: boolean
}

export interface IJobRoomMessage {
  id: string
  sender_id: string
  content: string
  file_url: string
  file_name: string
  created_at: Date
  type: string
  is_read: boolean
}
export interface JobRoom {
  job_id: string
  applicant_id: string
  agent_member_id: string
  agent_member_id_as_company?: string
  company_id?: string
  message_state: JobRoomMessageState | null
  updated_at?: firebase.firestore.FieldValue
}

export interface JobRoomMessage {
  sender_id: string
  content: string
  file_url: string
  file_name: string
  type: string
  created_at?: firebase.firestore.FieldValue
  is_read: boolean
}

/**
 * 該当のチャットルームにあるチャットメッセージを件数指定して取得する
 * @param room_id ルームid
 * @param size 取得する件数
 * @param senderId 送信者id
 * @returns 件数指定分のチャットメッセージ
 */
export const getJobMessages = async (
  room_id: string,
  size: number,
  sender_id: string
): Promise<IJobRoomMessage[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const query = db
        .collection("job_rooms")
        .doc(room_id)
        .collection("messages")
        .orderBy("created_at", "desc")
        .limit(size)
      const querySnapshot = await query.get()
      const messageList: IJobRoomMessage[] = []
      for (const doc of querySnapshot.docs) {
        if (doc.exists) {
          if (doc.data().sender_id !== sender_id && !doc.data().is_read) {
            await doc.ref.update({
              is_read: true, // 既読に更新
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            })

            messageList.push({
              id: doc.id,
              sender_id: doc.data().sender_id,
              content: doc.data().content,
              file_url: doc.data().file_url,
              file_name: doc.data().file_name,
              created_at: doc.data()?.created_at.toDate(),
              type: doc.data().type,
              is_read: true,
            })
          } else {
            messageList.push({
              id: doc.id,
              sender_id: doc.data().sender_id,
              content: doc.data().content,
              file_url: doc.data().file_url,
              file_name: doc.data().file_name,
              created_at: doc.data()?.created_at.toDate(),
              type: doc.data().type,
              is_read: doc.data().is_read,
            })
          }
        }
      }
      resolve(messageList)
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * 該当のチャットルームにある全チャットメッセージを取得する
 * @param room_id ルームid
 * @param senderId 送信者id
 * @returns 全チャットメッセージのオブジェクト配列
 */
export const getJobAllMessagesAsync = async (
  room_id: string,
  sender_id: string
): Promise<IJobRoomMessage[]> => {
  const querySnapshot = await db
    .collection("job_rooms")
    .doc(room_id)
    .collection("messages")
    .orderBy("created_at", "desc")
    .get()
  const messageList: IJobRoomMessage[] = []
  for (const doc of querySnapshot.docs) {
    if (doc.exists) {
      if (doc.data().sender_id !== sender_id && !doc.data().is_read) {
        await doc.ref.update({
          is_read: true, // 既読に更新
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        })

        messageList.push({
          id: doc.id,
          sender_id: doc.data().sender_id,
          content: doc.data().content,
          file_url: doc.data().file_url,
          file_name: doc.data().file_name,
          created_at: doc.data()?.created_at.toDate(),
          type: doc.data().type,
          is_read: true,
        })
      } else {
        messageList.push({
          id: doc.id,
          sender_id: doc.data().sender_id,
          content: doc.data().content,
          file_url: doc.data().file_url,
          file_name: doc.data().file_name,
          created_at: doc.data()?.created_at.toDate(),
          type: doc.data().type,
          is_read: doc.data().is_read,
        })
      }
    }
  }
  return messageList
}

export const findJobRoomById = async (
  room_id: string,
  sender_id: string
): Promise<IJobRoom> => {
  return new Promise(async (resolve, reject) => {
    try {
      const doc = await db.collection("job_rooms").doc(room_id).get()
      if (!doc.exists) {
        throw new Error("Chat room not found")
      }
      const messages = await getJobMessages(doc.id, chatPageSize, sender_id)
      const room = {
        id: doc.id,
        job_id: doc.data().job_id,
        applicant_id: doc.data().applicant_id,
        agent_member_id: doc.data().agent_member_id,
        company_id: doc.data().company_id,
        message_state: doc.data().message_state,
        agent_member_id_as_company: doc.data().agent_member_id_as_company,
        messages: messages,
        is_user_blocked: doc.data().is_user_blocked,
        blocked_by: doc.data().blocked_by,
        updated_at: doc.data().updated_at?.toDate(),
      }
      resolve(room)
    } catch (error) {
      reject(error)
    }
  })
}

export const findAndRefreshJobRoomAndMessage = async (
  job_id: string,
  applicant_id: string,
  sender_id: string
): Promise<IJobRoom> => {
  const querySnapshot = await db
    .collection("job_rooms")
    .where("job_id", "==", job_id)
    .where("applicant_id", "==", applicant_id)
    .orderBy("updated_at", "desc")
    .get()

  let room: IJobRoom
  if (querySnapshot.docs.length > 0) {
    if (querySnapshot.docs.length == 1) {
      const promisesMessages = []
      querySnapshot.docs.map((doc) => {
        promisesMessages.push(getJobMessages(doc.id, chatPageSize, sender_id))
        room = Object.assign({}, { ...doc.data(), id: doc.id } as any)
      })
      const messages = await Promise.all(promisesMessages)
      room.messages = _.flatten(messages)
      room.paginateEnable = true
      return room
    }
    const promisesMessages = []
    querySnapshot.docs.map((doc) => {
      promisesMessages.push(getJobAllMessagesAsync(doc.id, sender_id))
      room = Object.assign({}, { ...doc.data(), id: doc.id } as any)
    })
    const messages = await Promise.all(promisesMessages)
    const flattenMessages = _.chain(messages)
      .flatten()
      .orderBy(["created_at"], ["desc"])
      .value()
    room.messages = flattenMessages
    room.paginateEnable = false
    room.message_state = querySnapshot.docs[0].data().message_state
    return room
  }
  return room
}

export const createJobRoom = async (room: JobRoom): Promise<IJobRoom> => {
  return new Promise(async (resolve, reject) => {
    try {
      room.updated_at = firebase.firestore.FieldValue.serverTimestamp()
      const docRef = await db.collection("job_rooms").add(room)
      const doc = await docRef.get()
      resolve({
        id: doc.id,
        job_id: doc.data().job_id,
        applicant_id: doc.data().applicant_id,
        agent_member_id: doc.data().agent_member_id,
        company_id: doc.data().company_id,
        message_state: doc.data().message_state,
        agent_member_id_as_company: doc.data().agent_member_id_as_company,
        messages: [],
        updated_at: doc.data().updated_at?.toDate(),
      })
    } catch (error) {
      reject(error)
    }
  })
}

export const updateJobRoomMessageState = (
  room_id: string,
  new_state: JobRoomMessageState
) => {
  return new Promise(async (resolve, reject) => {
    try {
      await db.collection("job_rooms")?.doc(room_id).update({
        message_state: new_state,
        updated_at: firebase.firestore.FieldValue.serverTimestamp(),
      })
      resolve(true)
    } catch (error) {
      reject(error)
    }
  })
}

export const unseenMessageCheck = async (
  job_id: string,
  applicant_id: string,
  message_state: JobRoomMessageState,
  unseenMessageHandler: (state: boolean) => void
) => {
  const query = db
    .collection("job_rooms")
    .where("job_id", "==", job_id)
    .where("applicant_id", "==", applicant_id)
    .limit(1)
  const querySnapshot = await query.get()
  for (const doc of querySnapshot.docs) {
    unseenMessageHandler(doc.data().message_state === message_state)
  }
}

export const checkNewJobMessageForAgent = async (
  agent_member_id: string,
  unseenMessageHandler: (state: boolean) => void
) => {
  const query = db
    .collection("job_rooms")
    .where("agent_member_id", "==", agent_member_id)
    .where("message_state", "==", JobRoomMessageState.AgentNotSeen)
    .limit(1)
  return query.onSnapshot((snapshot) => {
    if (snapshot.docs.length > 0) {
      unseenMessageHandler(true)
    } else {
      unseenMessageHandler(false)
    }
  })
}

export const jobChatMessageDeleteListener = async (
  room_id,
  deleteMessageHandler: (id: string) => void
) => {
  const query = db.collection("job_rooms").doc(room_id).collection("messages")
  return query.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "removed") {
        deleteMessageHandler(change.doc.id)
      }
    })
  })
}

export const checkNewJobMessageForAgentAsCompany = async (
  agent_member_id_as_company: string,
  unseenMessageHandler: (state: boolean) => void
) => {
  const query = db
    .collection("job_rooms")
    .where("agent_member_id_as_company", "==", agent_member_id_as_company)
    .where("message_state", "==", JobRoomMessageState.AgentCompanyNotSeen)
    .limit(1)
  return query.onSnapshot((snapshot) => {
    unseenMessageHandler(snapshot.docs.length > 0)
  })
}

export const checkNewJobMessageForCompany = async (
  company_id: string,
  unseenMessageHandler: (state: boolean) => void
) => {
  const query = db
    .collection("job_rooms")
    .where("company_id", "==", company_id)
    .where("message_state", "==", JobRoomMessageState.CompanyNotSeen)
    .limit(1)
  return query.onSnapshot((snapshot) => {
    unseenMessageHandler(snapshot.docs.length > 0)
  })
}

export const checkJobMessageStateForCompany = async (
  company_id: string,
  jobMessageStateHandler: (
    message_state: JobRoomMessageState,
    job_id: string,
    applicant_id: string
  ) => void
) => {
  const query = db
    .collection("job_rooms")
    .where("company_id", "==", company_id)
    .orderBy("updated_at", "desc")
    .limit(1)
  return query.onSnapshot((snapshot) => {
    snapshot.forEach((doc) => {
      if (!doc.metadata.hasPendingWrites) {
        jobMessageStateHandler(
          doc.data().message_state,
          doc.data().job_id,
          doc.data().applicant_id
        )
      }
    })
  })
}

export const checkJobMessageStateForAgent = async (
  agent_member_id: string,
  jobMessageStateHandler: (
    message_state: JobRoomMessageState,
    job_id: string,
    applicant_id: string
  ) => void
) => {
  const query = db
    .collection("job_rooms")
    .where("agent_member_id", "==", agent_member_id)
    .orderBy("updated_at", "desc")
    .limit(1)
  return query.onSnapshot((snapshot) => {
    snapshot.forEach((doc) => {
      if (!doc.metadata.hasPendingWrites) {
        jobMessageStateHandler(
          doc.data().message_state,
          doc.data().job_id,
          doc.data().applicant_id
        )
      }
    })
  })
}

export const checkJobMessageStateForAgentAsCompany = async (
  agent_member_id_as_company: string,
  jobMessageStateHandler: (
    message_state: JobRoomMessageState,
    job_id: string,
    applicant_id: string
  ) => void
) => {
  const query = db
    .collection("job_rooms")
    .where("agent_member_id_as_company", "==", agent_member_id_as_company)
    .orderBy("updated_at", "desc")
    .limit(1)
  return query.onSnapshot((snapshot) => {
    snapshot.forEach((doc) => {
      if (!doc.metadata.hasPendingWrites) {
        jobMessageStateHandler(
          doc.data().message_state,
          doc.data().job_id,
          doc.data().applicant_id
        )
      }
    })
  })
}

export const sendJobMessage = async (
  room_id: string,
  message_state: JobRoomMessageState,
  lastMsgBy,
  message: JobRoomMessage
): Promise<IJobRoomMessage> => {
  return new Promise(async (resolve, reject) => {
    try {
      message.created_at = firebase.firestore.FieldValue.serverTimestamp()
      const documentRef = await db
        .collection("job_rooms")
        .doc(room_id)
        .collection("messages")
        .add(message)
      const newMessage = await documentRef.get()
      await db.collection("job_rooms").doc(room_id).set(
        {
          message_state: message_state,
          last_message_by: lastMsgBy,
          last_message_at: firebase.firestore.FieldValue.serverTimestamp(),
          email_status: "default",
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
      resolve({
        id: newMessage.id,
        sender_id: newMessage.data().sender_id,
        content: newMessage.data().content,
        file_url: newMessage.data().file_url,
        file_name: newMessage.data().file_name,
        created_at: newMessage.data().created_at.toDate(),
        type: newMessage.data().type,
        is_read: newMessage.data().is_read,
      })
    } catch (error) {
      reject(error)
    }
  })
}

export const registerNewJobMessageListener = (
  room_id: string,
  handleNewMessage: (message: IJobRoomMessage) => void
) => {
  const query = db
    .collection("job_rooms")
    .doc(room_id)
    .collection("messages")
    .orderBy("created_at", "desc")
    .limit(1)

  return query.onSnapshot((snapshot) => {
    snapshot.forEach((doc) => {
      if (!doc.metadata.hasPendingWrites) {
        handleNewMessage({
          id: doc.id,
          sender_id: doc.data().sender_id,
          content: doc.data().content,
          file_url: doc.data().file_url,
          file_name: doc.data().file_name,
          created_at: doc.data().created_at.toDate(),
          type: doc.data().type,
          is_read: doc.data().is_read,
        })
      }
    })
  })
}

export const findAllJobMessages = async (
  room_id: string,
  lastMessageID: string,
  sender_id: string
): Promise<IJobRoomMessage[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      let query = db
        .collection("job_rooms")
        .doc(room_id)
        .collection("messages")
        .orderBy("created_at", "desc")
        .limit(chatPageSize)
      if (lastMessageID) {
        const lastDoc = await db
          .collection("job_rooms")
          .doc(room_id)
          .collection("messages")
          .doc(lastMessageID)
          .get()
        query = query.startAfter(lastDoc)
      }
      const querySnapshot = await query.get()
      const messageList = []
      for (const doc of querySnapshot.docs) {
        if (doc.exists) {
          if (doc.data().sender_id !== sender_id && !doc.data().is_read) {
            await doc.ref.update({
              is_read: true, // 既読に更新
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            })

            messageList.push({
              id: doc.id,
              sender_id: doc.data().sender_id,
              content: doc.data().content,
              file_url: doc.data().file_url,
              file_name: doc.data().file_name,
              created_at: doc.data()?.created_at.toDate(),
              type: doc.data().type,
              is_read: true,
            })
          } else {
            messageList.push({
              id: doc.id,
              sender_id: doc.data().sender_id,
              content: doc.data().content,
              file_url: doc.data().file_url,
              file_name: doc.data().file_name,
              created_at: doc.data()?.created_at.toDate(),
              type: doc.data().type,
              is_read: doc.data().is_read,
            })
          }
        }
      }
      resolve(messageList)
    } catch (error) {
      reject(error)
    }
  })
}

export const updateJobRoomUserBlockStatus = (
  room_id: string,
  new_status: boolean,
  blocked_by_user: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      room_id &&
        (await db
          .collection("job_rooms")
          ?.doc(room_id)
          .update({
            is_user_blocked: new_status,
            blocked_by: new_status ? blocked_by_user : null,
          }))
      resolve(true)
    } catch (error) {
      reject(error)
    }
  })
}
